import PNotify from 'pnotify/dist/pnotify';
import PNotifyButtons from 'pnotify/dist/pnotify.buttons';

PNotify.prototype.options.styling = "bootstrap3";

var stackOptsDef = {
    "dir1": "down", 
    "dir2": "left", 
    "push": "bottom", 
    "spacing1": 10, 
    "spacing2": 0, 
    "context": $("body"), 
    "modal": false, 
    "firstpos1": 65, 
    "firstpos2": 10
};

export var showMsg = function(type, text, stackOpts, hide) {
    if (stackOpts === undefined) {
        var stackOpts = stackOptsDef;
    }
    if (hide === undefined) {
        var hide = true;
    }
    new PNotify({
        text: text,
        type: type,
        icon: false,
        stack: stackOpts,
        hide: hide
    });
}