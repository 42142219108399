const plugin = fpAPI => {

    const { addFilter, utils } = fpAPI;
    const { Type, createRoute } = utils;

    // called for each view that is created right after the 'create' method
    addFilter('CREATE_VIEW', viewAPI => {
        // get reference to created view
        const { is, view, query } = viewAPI;
        // only hook up to item view and only if is enabled for this cropper
        if (!is('file')) {
            return;
        }

        // dołącza przycisk download
        const didLoadItem = ({ root, props }) => {

            const { id } = props;
            const item = query('GET_ITEM', id);

            // tylko jeżeli element istnieje i odpowiedni status
            if (!item || item.status !== 5) {
                return;
            }
            var el = document.createElement('a');
            el.innerHTML = '<span class="fa fa-arrow-down"></span>';
            el.href = '/files/' + item.source;
            el.classList.add('filepond--action-download');
            
            root.appendChild(el);
        };

        // start writing
        view.registerWriter(
            createRoute({
                DID_LOAD_ITEM: didLoadItem,
            }, ({ root, props }) => {

                if (!root.is('file')) {
                    return;
                }
                
            })
        );
    });

    // expose plugin
    return {
        options: {

            // Enable or disable file poster
            allowDownload: [true, Type.BOOLEAN],
        }
    };
};

// fire pluginloaded event if running in browser, this allows registering the plugin when using async script tags
const isBrowser = typeof window !== 'undefined' && typeof window.document !== 'undefined';
if (isBrowser) {
    document.dispatchEvent(new CustomEvent('FilePond:pluginloaded', { detail: plugin }));
}

export default plugin;