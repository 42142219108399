import * as FilePond from 'filepond';
import FilePondPluginFileMetadata from 'filepond-plugin-file-metadata';
import FilePondPluginDownload from './filepond-plugin-download';
import {pleaseWait} from 'please-wait';
 
var appMessages = require('./app-messages');

window.cmjp2Files = function(inputElement, relType, relId, section, maxFiles, readOnly, remove) {
    var readOnly = readOnly || $(inputElement).prop('readonly');
    console.log("Sprawdzam załączniki");
    console.log(readOnly);
    console.log(maxFiles);
    
    const baseUrl = '/files';
    var wrapEl = $(inputElement).wrap('<div class="file-list-wrap"></div>').parent();
    var stackOpts = {
        "dir1": "down", 
        "dir2": "left", 
        "push": "bottom", 
        "spacing1": 10, 
        "spacing2": 0, 
        "context": $("body"), 
        "modal": false, 
        "firstpos1": 100, 
        "firstpos2": 10
    };
    var cmjp2FilePondOptions = {
        allowFileMetadata: true,
        allowPaste: false,
        labelIdle: 'Przeciągnij pliki tutaj lub <span class="filepond--label-action">dodaj z urządzenia</span>',
        labelInvalidField: 'W polu znajdują się nieprawidłowe pliki',
        labelFileWaitingForSize: 'Oczekuję na rozmiar pliku',
        labelFileSizeNotAvailable: 'Rozmiar pliku nie dostępny',
        labelFileLoading: 'Trwa ładowanie pliku',
        labelFileLoadError: 'Wystąpił problem podczas ładowania pliku',
        labelFileProcessing: 'Trwa zapisywanie pliku na serwerze',
        labelFileProcessingComplete: 'Zapisywanie pliku na serwerze zakończone',
        labelFileProcessingAborted: 'Zapisywanie pliku przerwane',
        labelFileProcessingError: 'Wystąpił błąd podczas zapisywania pliku',
        labelFileProcessingRevertError: 'Cofnięcie zapisu pliku nie powiodło się',
        labelFileRemoveError: 'Błąd podczas usuwania',
        labelTapToCancel: 'kliknij aby przerwać',
        labelTapToRetry: 'kliknij aby spróbować ponownie',
        labelTapToUndo: 'kliknij aby cofnąć',
        server: {
            url: baseUrl,
            process: {
                headers: {
                    'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
                },
                onload: function(r) {
                    return r;
                },
                onerror: function(r) {
                    try {
                        var info = JSON.parse(r);
                        if (typeof info === 'object' && info.hasOwnProperty('err') && Array.isArray(info.err)) {
                            appMessages.showMsg('error', info.err[0], stackOpts)
                            return 
                        }
                    } catch (e) {
                        console.log("Wystąpił problem z zapisaniem pliku:");
                        console.log(e);
                    }
                    appMessages.showMsg('error', 'Wystąpił problem z zapisaniem pliku', stackOpts);
                }
            },
            revert: {
                headers: {
                    'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
                },
            },
            remove: (source, load, error) => {
                $.ajax(baseUrl, {
                    data: source,
                    type: 'DELETE',
                    processData: false,
                    contentType: 'text/plain',
                })
                    .done(function (data) {
                        load();
                    })
                    .fail(function (jqXHR, textStatus) {
                        var errorMessage = 'Usunięcie pliku nie powiodło się';
                        if(jqXHR['responseJSON']['err'].length > 0) {
                            errorMessage = jqXHR['responseJSON']['err'];
                        }
                        appMessages.showMsg('error', errorMessage, stackOpts)
                        error('Wystąpił błąd przy usuwaniu pliku');
                    });
            },
            fetch: './fetch',
            load: './load',
        },
        beforeRemoveFile: (file) => {
            return confirm('Czy na pewno chcesz usunąć załącznik?');
        },
        onupdatefiles: (files) => {
            console.log('onupdatefiles', files);
            var len = files.length;
            var filesInfo = [];
            for (var i = 0; i < len; i++) {
                //Tylko poprawnie załadowane
                var file = files[i];
                //if (file.status === 5) {
                    filesInfo.push(
                        {
                            name: file.filename
                        }
                    );
                //}
            }
            $(inputElement).trigger('cmjp2FilesChange', [filesInfo]);
            console.log("dodano skan");
        },
        onprocessfiles: () => {
            console.log("skan został dodany");
        }
    };

    var readOnlyFileList = function(files, baseUrl, wrapEl) {
        var cnt = files.length;
        var fileListEl = $('<div class="file-list-ro"/>');
        
        if (cnt !== 0) {
            for (var i = 0; i < cnt; i++) {
                var file = files[i];
                var fileEl = $('<div class="file-list-file"/>');
                var fileIconClass;
                switch (file.options.file.type) {
                    case 'application/pdf':
                        fileIconClass = 'fa-file-pdf-o';
                        break;
                    case 'image/jpeg':
                    case 'image/png':
                        fileIconClass = 'fa-file-image-o';
                        break;
                    default:
                        fileIconClass = 'fa-file-o';
                        break;
                }
                var fileUrl = baseUrl + '/' + file.source
                var fileNameEl = $('<a/>');
                fileNameEl.attr('href', fileUrl);
                fileNameEl.text(file.options.file.name)
                fileEl.html('<span class="fa ' + fileIconClass + '"></span>&nbsp;&nbsp;');
                fileEl.append(fileNameEl);
                fileListEl.append(fileEl);
            }
        } else {
            fileListEl.html('Brak plików');
        }

        wrapEl.append(fileListEl);
    }

    FilePond.registerPlugin(FilePondPluginFileMetadata);
    FilePond.registerPlugin(FilePondPluginDownload);
    FilePond.setOptions(cmjp2FilePondOptions);
    var pleaseW = pleaseWait(cmjp2PleaseWaitSettings);
    var sectionUuid = $(inputElement).attr('data-section') !== undefined &&
            $(inputElement).attr('data-section') != null &&
            $(inputElement).attr('data-section') != '' ? $(inputElement).attr('data-section') : null;
    
    $.get(baseUrl, {rel_type: relType, rel_id: relId, section: section, section_uuid: sectionUuid})
        .done(function(data) {
            var files = [];
            data.forEach(element => {
                files.push({
                    source: element.uuid,
                    options: {
                        type: 'local',
                        file: {
                            name: element.name,
                            type: element.content_type,
                            size: element.size,
                        }
                    }
                });
            });
            var scanDeletable = $(inputElement).attr('data-deletable-scan') !== undefined;
            
            console.log(readOnly);
            console.log(section);
         
            if ((readOnly === false && (section !== 'scans' || scanDeletable || (section === 'scans' && files.length === 0))) || (section === 'questionnaire' && readOnly === false)) {
                //const inputElement = document.querySelector('input[type="file"]');
                
                console.log(section);
                console.log(inputElement);
                console.log(relType);
                console.log(relId);
                console.log("Koniec: "+section);
                
                
                    const pond = FilePond.create(inputElement, {
                        fileMetadataObject: {
                            rel_type: relType,
                            rel_id: relId,
                            section: section,
                        },
                        allowMultiple: maxFiles > 1,
                        maxFiles: maxFiles,
                        files: remove!=null && remove?[]:files,
                    });
                
                    console.log("Utworzono: "+section);
                                                        
                    if(remove!=null && remove)
                    {                                        
                        FilePond.find(inputElement).removeFiles();                    
                    }
                    $(inputElement).show();
                    pond.on('updatefiles', function(files){
                        var len = files.length;
                        var list = new DataTransfer();
                        for (var i = 0; i < len; i++) {
                            var file = new File(['content'], files[i].file.name);
                            list.items.add(file);
                        }
                        inputElement.files = list.files;
                        var id = $(inputElement).attr('id');
                        //pobierz element input file po modyfikacjach filepond
                        $('#'+id+' input[type="file"]').trigger('change');
                    });
                pond.on('processfiles', function(){
                    if(section === 'scans') {
                        console.log("skan wniosku został wgrany");
                        var $modal = $('#app-received-modal');
                        $modal.modal({backdrop: 'static', keyboard: false});

                        $('#app-received-cancel-button').click(function() {
                            $modal.modal("hide");
                            window.location.href = "/wnioski";
                        });
                    }
                    
                    if(section === 'scan_completed_box') {
                        console.log("skan wniosku został wgrany");
                        var $modal = $('#app-received-modal');
                        $modal.modal({backdrop: 'static', keyboard: false});

                        $('#app-received-cancel-button').click(function() {
                            $modal.modal("hide");
                            window.location.href = "/wnioski";
                        });
                    }
                    
                    if(section === 'agree_final') {
                        console.log("umowa została wgrana");
                        var $modal = $('#app-agree-modal');
                        $modal.modal({backdrop: 'static', keyboard: false});

                        $('#app-agree-cancel-button').click(function() {
                            $modal.modal("hide");
                            window.location.href = "/wnioski";
                        });
                    }
                    
                    if(section === 'raport_scans') {
                        console.log("umowa została wgrana");
                        var $modal = $('#raport-modal');
                        $modal.modal({backdrop: 'static', keyboard: false});

                        $('#raport-cancel-button').click(function() {
                            $modal.modal("hide");
                            window.location.href = "/ankiety";
                        });
                    }
                    
                });
                
            } else {
                wrapEl.empty();
                readOnlyFileList(files, '/files', wrapEl);
            }
        })
        .fail(function (data) {
            console.log('Błąd pobierania listy załadowanych plików');
            console.log(data);
            var msg = 'Pobranie załadowanych plików nie powiodło się. ';
            if (data.status === 403) {
                msg = msg + ' Brak dostępu.';
            }
            appMessages.showMsg('error', msg, stackOpts)
            $(inputElement).hide();
        })
        .always(function () {
            pleaseW.finish();
        });
}