
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
import dtBootstrap from 'datatables.net-bs';
import dtButtonsBootstrap from 'datatables.net-buttons-bs';
import dtResponsiveBootstrap from 'datatables.net-responsive-bs';

import summerNote from 'summernote';
import summerNoteCleaner from 'summernote-cleaner';

//import ckeditorBase from 'ckeditor/basePath';

require('./files.js');

$.ajaxSetup({
    headers: {
      'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
  });

//Ustawienia please wait
window.cmjp2PleaseWaitSettings = {
        backgroundColor: 'rgba(255, 255, 255, 1)',
        loadingHtml: '<div class="sk-folding-cube"><div class="sk-cube1 sk-cube"></div><div class="sk-cube2 sk-cube"></div><div class="sk-cube4 sk-cube"></div><div class="sk-cube3 sk-cube"></div></div><div>Proszę czekać...</div>',
        template: "<div class='pg-loading-inner'><div class='pg-loading-center-outer'><div class='pg-loading-center-middle'><div class='pg-loading-html'></div></div></div></div>"
}

/**
 * accessbility
 * #font-normal
 * #font-medium
 * #font-big
 * #high-contrast
 */
// Create cookie
function createCookie(name, value, days) {
    var expires;
    if (days) {
        var date = new Date();
        date.setTime(date.getTime()+(days*24*60*60*1000));
        expires = "; expires="+date.toGMTString();
    }
    else {
        expires = "";
    }
    document.cookie = name+"="+value+expires+"; path=/";
}

// Read cookie
function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1,c.length);
        }
        if (c.indexOf(nameEQ) === 0) {
            return c.substring(nameEQ.length,c.length);
        }
    }
    return null;
}

// Erase cookie
function eraseCookie(name) {
    createCookie(name,"",-1);
}

var htmlTag = document.getElementsByTagName("html")[0];

var externalTags = document.querySelectorAll('a[rel="external"]'), i;
for (i = 0; i < externalTags.length; ++i) externalTags[i].setAttribute('target','_blank');

var accessbilityFontTags = document.querySelectorAll('#accessbility .font a[rel="font"]');
var cookieFontSize = readCookie('fontSize');
if (cookieFontSize != null) {
  for (i = 0; i < accessbilityFontTags.length; ++i) accessbilityFontTags[i].classList.remove('current');
  htmlTag.classList.add(cookieFontSize);
  document.querySelector('#accessbility .font .'+cookieFontSize).classList.add('current');
}

for (i = 0; i < accessbilityFontTags.length; ++i) {
  accessbilityFontTags[i].addEventListener('click', function(el){
    for (var j = 0; j < accessbilityFontTags.length; ++j) {
      accessbilityFontTags[j].classList.remove('current');
      htmlTag.classList.remove(accessbilityFontTags[j].classList[0]);
    }
    createCookie("fontSize", el.target.classList[0], 30);
    el.target.classList.add('current');
    htmlTag.classList.add(el.target.classList[0]);
    return false;
  });
}

if (readCookie('contrastHigh')) htmlTag.classList.add('contrast-high');
document.querySelector('#accessbility .font a[rel="contrast"]').addEventListener('click', function(el){
  readCookie('contrastHigh')?eraseCookie('contrastHigh'):createCookie('contrastHigh', true, 30);
  htmlTag.classList.toggle('contrast-high');
});



$(document).ready(function() {
    
    //Podpięcie pod pola .rich-text edytora summernote
    $('.rich-text').each(function() {
        var options = {
            height: 200,
            lang: 'pl-PL',
            toolbar: [
                ['style', ['style']],
                ['font', ['bold', 'italic', 'underline', 'clear']],
                ['para', ['ul', 'ol']],
                ['fontsize', ['fontsize']],
                ['table', ['table']],
                ['insert', ['link', 'picture', 'video']],
                ['view', ['fullscreen', 'codeview', 'help']]
              ],
              cleaner: {
                keepHtml: true,
                newline: '<br>',
                keepOnlyTags: ['<p>', '<br>', '<ul>', '<li>', '<b>', '<strong>','<i>', '<a>', '<span>', '<b>', '<ul>', '<li>', 
                    '<h1>', '<h2>', '<h3>', '<h4>', '<h5>', '<h6>', '<u>'
                ],
                badTags: ['style', 'script', 'applet', 'embed', 'noframes', 'noscript'],
                badAttributes: ['start'],
				 limitChars: 100, 
				  limitDisplay: 'both',
				  limitStop: true
              }
        };
        //Wszystkie atrybuty zaczynające się od data-rich-text- są przekazywane jako opcje do summernote
        $.each(this.attributes, function () {
            if (this.name.match(/^data-rich-text-/)) {
                var key = this.name.replace(/^data-rich-text-(.+)$/, '$1');
                options[key] = this.value;
            }
        });
        $(this).summernote(options);
    });

    if (document.getElementById('logout')) {
        document.getElementById('logout').addEventListener('click', function () {
            event.preventDefault();
            document.getElementById('logout-form').submit();
        });
    }

    if (document.getElementById('organization-select')) {
        document.getElementById('organization-select').addEventListener('change', function () {
            event.preventDefault();
            this.form.submit();
        });
    }
    
    /**
     * Zabezpieczenie przed autocomplete
     */
    $('input[type=password]').each(function() {
        $(this).prop("readonly", true);
    });
    $('input[type=password]').on('focus', function() {
        $(this).prop("readonly", false);
    });
});

//Tłumaczy (required) na (wymagane)
window.translateApplicationFormRequire = function() {
    $(".alpaca-required-indicator").html('<span class="alpaca-required-indicator-text-style">(wymagane)</span>');
}
